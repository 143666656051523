import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class NotificationService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/notification`;

      }
      all() {
            let url = `${this.#api}-all`;
            return apiService.get(url);
      }


      paginate(type = null) {
            let url = `${this.#api}`;
            if (type)
                  url = url + '?type=' + type;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }
      getByUser(id) {
            let url = `${this.#api}/${id}/get`
            return apiService.get(url);
      }
      getAllByUser(index = null,data=null) {
            let url = `${this.#api}/get-by-user/all`

            if (index)
                  data.page = index;
            let param = {
                  params: data
            }
            return apiService.query(url, param);
      }
      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

      countNotificationByUser(id) {
            let url = `${this.#api}/${id}/count`
            return apiService.get(url);
      }

      markAsViewed(notificationId){
            let url = `${this.#api}/${notificationId}/mark-as-viewed`;
            return apiService.get(url);
      }

      getPrintFiles(index = null,data=null,notificationId){
            let url = `${this.#api}/print/${notificationId}/files`

            if (index)
                  data.page = index;
            let param = {
                  params: data
            }
            return apiService.query(url, param);
      }
}
