<template>
    <v-app>

        <v-dialog max-width="800" scrollable v-model="notification_dialog">
            <v-card v-if="notification">
                <v-card-title>
                    {{notification.description}}
                    <span class="font-size-sm" v-if="notification.notified_at">
                                                      {{notification.notified_at | moment("from", "now")}}
                                                </span>
                    <v-img :src="notification.image_path['real']" contain max-height="400"
                           v-if="notification.image"></v-img>
                </v-card-title>
                <v-card-text>

                </v-card-text>
                <v-card-actions v-if="notification.link">
                    <v-btn :to="notification.link" block class="btn btn-primary text-white"
                           target="_blank">
                        View
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

      <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="card-title pt-3 px-3 d-flex justify-content-between">
                        <div class="breadcrumb-left">
                            <h4>Notifications</h4>
                            <div class="breadcrumb-sub-header">
                                <router-link to="/dashboard">Dashboard</router-link>
                                \ Notifications
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card-body">
                    <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                        <div class="row">
                            <v-col cols="12" md="3">
                                <v-text-field
                                        v-model="search.title"
                                        label="Title"
                                        outlined
                                        dense
                                        clearable
                                        v-on:keyup.enter="getNotifications">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-menu
                                        ref="menuDate"
                                        v-model="menuStartDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="search.start_date"
                                                label="View from date"
                                                readonly
                                                outlined
                                                dense
                                                clearable
                                                v-bind="attrs"
                                                @input="search.start_date = $event !== null ? $event : ''"
                                                v-on="on"
                                                v-on:keyup.enter="getNotifications"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="search.start_date"
                                            outlined
                                            dense
                                            no-title
                                            @input="menuStartDate = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-menu
                                        ref="menuDate"
                                        v-model="menuEndDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="search.end_date"
                                                label="View up to date"
                                                readonly
                                                outlined
                                                dense
                                                clearable
                                                v-bind="attrs"
                                                v-on="on"
                                                @input="search.end_date = $event !== null ? $event : ''"
                                                v-on:keyup.enter="getNotifications"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="search.end_date"
                                            outlined
                                            dense
                                            no-title
                                            :min="search.start_date"
                                            @input="menuEndDate = false"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3" class="text-right">
                                <v-btn :loading="is_loading"
                                        class="btn btn-primary btn-block w-35 float-right"
                                        @click="getNotifications">
                                    <i class="fa fa-search"></i> Search
                                </v-btn>
                            </v-col>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <v-skeleton-loader
                                type="table-thead"
                                v-if="is_loading">
                        </v-skeleton-loader>

                        <v-skeleton-loader
                                v-if="is_loading"
                                type="table-row-divider@25">
                        </v-skeleton-loader>

                        <table class="table">
                            <thead>
                              <tr class="px-3">
<!--                                <th class="px-3">-->
<!--                                  <v-checkbox class="px-3" @change="selectAll()" v-model="checkAll"></v-checkbox>-->
<!--                                </th>-->
                                <th class="px-3">Title</th>
                                <th class="px-3">Date/Time</th>
                                <th class="pr-3 text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                                <tr v-bind:class="{ 'not-read': !item.is_viewed, 'read': item.is_viewed }" v-for="(item, index) in notifications" :key="index">
<!--                                    <td class="px-3">-->
<!--                                      <v-checkbox v-model="selected" :value="item"></v-checkbox>-->
<!--                                    </td>-->
                                    <td class="px-2">
                                        <a  @click="viewNotification(item)"> {{item.title}} </a>
                                    </td>
                                    <td class="px-2">
                                        {{item.formatted_date_sent}} {{item.formatted_time}}
                                    </td>
                                    <td class="pr-0 text-center">
                                        <template>
                                            <b-dropdown
                                                    size="sm"
                                                    variant="link"
                                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                    no-caret
                                                    right
                                                    no-flip
                                            >
                                                <template v-slot:button-content>
                                                    <i class="ki ki-bold-more-hor"></i>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover min-w-md-250px">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="!item.is_viewed">
                                                        <a @click="viewNotification(item)" class="navi-link">
                                          <span class="navi-icon">
                                             <i class="mdi mdi-eye-off"></i>
                                          </span>
                                                            <span class="navi-text">Set as read</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="item.is_viewed">
                                                        <a @click="markAsNotViewed(item)" class="navi-link">
                                          <span class="navi-icon">
                                             <i class="mdi mdi-eye-off"></i>
                                          </span>
                                                            <span class="navi-text">Set as unread</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a @click="deleteNotification(item.id)" class="navi-link">
                                          <span class="navi-icon">
                                             <i class="mdi mdi-delete"></i>
                                          </span>
                                                            <span class="navi-text">Delete</span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                                <!--end::Navigation-->
                                            </b-dropdown>
                                        </template>
                                    </td>
                                </tr>
                                <tr v-if="notifications.length == 0">
                                    <td colspan="5" class="text-center">
                                        <strong>Data not available</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <b-pagination
                                @input="getNotifications"
                                v-model="page"
                                v-if="notifications.length > 0"
                                :total-rows="total"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="pull-right mt-7"
                                :disabled="loading"
                        ></b-pagination>


                    </div>
                </div>
            </div>
        </div>
      </div>

    </v-app>
</template>

<script>
import NotificationService from "@/core/services/notification/NotificationService";

const notificationService = new NotificationService();
export default {
    name: "Notification",
    data() {
        return {
          selected: [],
          checkAll: false,
          menuStartDate: false,
          menuEndDate: false,
          notifications: [],
          notification: null,
          notification_dialog: false,
          attrs: false,
          loading: false,
          page: null,
          total: 0,
          isBusy: false,
          perPage: 25,
          is_loading: false,
          search:{
            title:'',
            start_date: '',
            end_date: '',
          },
        }
    },
    mounted() {
        this.getNotifications()
    },
    methods: {
      selectAll() {
        if (!this.checkAll) {
          this.selected = [];
        } else {
          this.notifications.forEach(item => {
            this.selected.push(item);
          })

        }
      },
      getNotifications() {
            this.is_loading = true;
            notificationService.getAllByUser(this.page,this.search).then((response) => {
                this.notifications = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.totalPage = response.data.meta.last_page;
              this.perPage = parseInt(response.data.meta.per_page);
              this.loading = false;
            }).catch(error => {

            }).finally(() => {
                this.is_loading = false;

            })
        },

      viewNotification(notification) {
        notification.is_viewed = true;
        notificationService.update(notification.id, notification).then((res) => {
          this.getNotifications();
        });
        if ((notification.type == "print_id_card"||notification.type == "result_certificate") && notification.status == 'ready') {
          //window.open(`${API_URL}notification/view/${notification.id}`, "_blank");
          this.$router.push({
            name: "print-job-queue",
            params: {userNotificationId: notification.id}
          });
          this.getNotifications();
        } else {
          this.getNotifications();
        }
        if(notification.type=='export'){
          window.open(notification.url);
        }
      },

      markAsNotViewed(notification){
        notification.is_viewed = false;
        notificationService.update(notification.id,notification).then(response => {
          this.$snotify.success("Set as unread");
          this.getNotifications();
        }).catch(() => {});
      },

      deleteNotification(id){
        this.$confirm({
          message: `Are you sure?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              notificationService
                .delete(id)
                .then(response => {
                  this.getNotifications();
                })
                .catch(error => {
                  //console.log(error);
                });
            }
          }
        });
      }
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },
    }
}
</script>

<style scoped>
.not-read {
  background-color: #e3e3e3 !important;
}
.read {
  background-color: #ffffff !important;
}
</style>
